import axios from 'axios'

const BASE_URL = import.meta.env.VITE_ALTHEA_BASE_URL
const CONFIG_NO_AUTH = { headers: { 'Content-Type': 'application/json' } }

const altheaUrl = (path) => {
  return BASE_URL + path
}

const authToken = () => {
  return localStorage.getItem('token')
}

const configWithAuth = () => {
  return {
    headers: {
      Authorization: authToken(),
      'Content-Type': 'application/json'
    }
  }
}

const handleError = (error) => {
  if (error.response?.status === 401) {
    localStorage.removeItem('token')
  }
  return error.response
}

export function useUnauthenticatedPost(path, data) {
  const result = axios.post(altheaUrl(path), data, CONFIG_NO_AUTH )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
  return result
}

export function useUnauthenticatedPatch(path, data) {
  const result = axios.patch(altheaUrl(path), data, CONFIG_NO_AUTH )
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
  return result
}

export function useAuthenticatedPatch(path, data) {
  const result = axios.patch(altheaUrl(path), data, configWithAuth())
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
  return result
}

export function useAuthenticatedPost(path, data) {
  const result = axios.post(altheaUrl(path), data, configWithAuth())
    .then((response) => {
      return response
    })
    .catch((error) => {
      return handleError(error)
    });
  return result
}

export function useAuthenticatedPut(path, data) {
  const result = axios.put(altheaUrl(path), data, configWithAuth())
    .then((response) => {
      return response
    })
    .catch((error) => {
      return handleError(error)
    })
  return result
}

export function useAuthenticatedGet(path, params = {}) {
  const config = { ...configWithAuth(), params }
  const result = axios.get(altheaUrl(path), config)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return handleError(error)
    });
  return result
}

export function useAuthenticatedDelete(path) {
  const result = axios.delete(altheaUrl(path), configWithAuth())
    .then((response) => {
      return response
    })
    .catch((error) => {
      return handleError(error)
    });
  return result
}
