import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUnauthenticatedPost, useAuthenticatedDelete } from '@/composables/request.js'

export const useUserStore = defineStore('user', () => {
  const token = ref(getLocalToken())

  async function logIn(email, password) {
    let response = await useUnauthenticatedPost('/login', { "user": { "email": email, "password": password }}, (error) => { console.log(error)})
    if (response.status === 200) {
      token.value = response.headers.authorization
      localStorage.setItem('token', token.value)
      localStorage.setItem('token_expires_at', response.data.expires_at)
    }
    return response
  }

  async function logOut() {
    const response = await useAuthenticatedDelete('/logout')
    if (response.status === 200) {
      token.value = null
      localStorage.removeItem('token')
      localStorage.removeItem('token_expires_at')
    } else {
      console.log(response.data)
    }
  }

  function getLocalToken() {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token');
    }
  }

  function tokenValid() {
    return parseInt(localStorage.getItem('token_expires_at')) > Math.floor(new Date().getTime()) / 1000
  }

  function isAuthenticated() {
    return (token.value != null && tokenValid())
  }

  return { logOut, logIn, token, isAuthenticated}
})
